
<template>
  <div class="m-3">
    <div class="card-body card-modal pt-0">
      <div style="height: 500px;">
        <h3 class="title">Editar Servicio</h3>
        <div class="row justify-content-center">
          <div class="col-12">
            <validation-observer ref="serviceEditForm">
            <b-form @submit="onSubmit">

              <!-- Email -->
              <b-form-group label="Nombre">
                <validation-provider
                  #default="{ errors }"
                  name="Nombre"
                  rules="required|max:255"
                >
                  <b-form-input
                    size="sm"
                    type="text"
                    v-model="service.name"
                    name="name"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              
              <!-- Tipo de servicio -->
              <b-form-group label="Tipo de servicio">
                <validation-provider
                  #default="{ errors }"
                  name="Tipo de servicio"
                  rules="required"
                >
                  <multiselect
                    v-model="service.type"
                    name="type"
                    :multiple="false"
                    :options="types"
                    label="label"
                    track-by="label"
                    :state="errors.length > 0 ? false : null"
                  ></multiselect>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              
              <!-- Provincia -->
              <b-form-group label="Provincia" v-if="service.type && service.type.label == 'colecta'">
                <validation-provider
                  #default="{ errors }"
                  name="Provincia"
                  rules="required"
                >
                  <b-form-select
                    v-model="service.province"
                    name="province"
                    :options="provinces"
                    @input="fetchLocations"
                    :disabled="true"
                    :state="errors.length > 0 ? false : null"
                  ></b-form-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              
              <!-- Localidad -->
              <b-form-group label="Localidad" v-if="service.type && service.type.label == 'colecta'">
                <validation-provider
                  #default="{ errors }"
                  name="Localidad"
                  rules="required"
                >
                  <multiselect
                    name="location"
                    v-model="service.locations"
                    :multiple="true"
                    :options="locations"
                    label="text"
                    track-by="text"
                    :state="errors.length > 0 ? false : null"
                  >
                    <template slot="beforeList">
                      <div class="select-button-box">
                        <b-button
                          size="sm"
                          @click="selectAllLocations(locations)"
                          class="mr-1"
                          variant="success"
                        >Seleccionar todos</b-button>
                        <b-button
                          size="sm"
                          @click="clearAllLocations()"
                          class="mr-1"
                          variant="danger"
                        >Limpiar</b-button>
                      </div>
                    </template>
                  </multiselect>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Tipo de cargo -->
              <b-form-group label="Tipo de cargo">
                <validation-provider
                  #default="{ errors }"
                  name="Tipo de cargo"
                  rules="required"
                >
                  <multiselect
                    v-model="charge_type"
                    name="charge_type"
                    :options="charge_types"
                    @input="fetchLocations"
                    :multiple="false"
                    :state="errors.length > 0 ? false : null"
                  ></multiselect>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              
              <!-- Porcentaje de cargo -->
              <b-form-group v-if="charge_type == 'porcentaje'" label="Porcentaje de cargo">
                <validation-provider
                  #default="{ errors }"
                  name="Porcentaje de cargo"
                  rules="required|integer|min:1|max:100"
                >
                  <b-form-input
                    name="percentage"
                    type="number"
                    placeholder="%"
                    v-model="service.percentage"
                    :state="errors.length > 0 ? false : null"
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              
              <!-- Monto -->
              <b-form-group v-if="charge_type == 'monto'" label="Monto">
                <validation-provider
                  #default="{ errors }"
                  name="Monto"
                  rules="required|min:1|max:50000"
                >
                  <b-form-input
                    name="amount"
                    type="number"
                    placeholder="100"
                    v-model="service.amount"
                    :state="errors.length > 0 ? false : null"
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-form>
            </validation-observer>
          </div>
        </div>
      </div>
    </div>
    <footer class>
      <div class="row">
        <div class="col-12">
          <b-button size="sm" class="mr-1 float-right" variant="primary" @click="onSubmit">Guardar</b-button>
          <b-button size="sm" class="mr-1 float-right" variant="outline-primary" @click="close">Cancelar</b-button>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from "vee-validate";

import ServiceService from "@/services/ServiceService";
import LocationService from "@/services/LocationService";

export default {
  name: "ServiceEdit",
  components:{
    ValidationProvider,
    ValidationObserver
  },
  props: {
    item: Object,
    onClose: Object,
    onSave: Object,
    edit: {
      type: Boolean,
      default: false
    },
    service: {
      default: () => {
        return {
          id: null,
          name: null,
          percentage: 0,
          type: null,
          amount: 0,
          postal_code: null,
          locations: [],
          province: null
        };
      }
    }
  },
  data() {
    return {
      types: [
        { value: "collect", label: "colecta" },
        { value: "others", label: "otros" }
      ],
      charge_types: ["porcentaje", "monto"],
      charge_type: null,
      locations: [],
      provinces: []
    };
  },
  methods: {
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    selectAllLocations(options) {
      this.service.locations = [...options];
    },
    clearAllLocations() {
      this.service.locations = [];
    },
    onSubmit(event) {
      event.preventDefault();
      this.$validator.validate().then(valid => {
        if (valid) {
          ServiceService.save(
            {
              name: this.service.name,
              type: this.service.type.value,
              percentage: this.service.percentage,
              amount: this.service.amount,
              postal_code: this.service.postal_code,
              location_ids: this.service.locations.map(o => o["value"])
            },
            this.item.id
          )
            .then(({ data }) => {
              this.$emit("close");
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Crear`,
                  text: `!Servicio guardado con éxito!`,
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
              this.onSave.handler(data);
            })
            .catch(error => {
              console.error(error);
              const response = error.response;
              if (response && response.status === 422) {
                const keys = Object.keys(response.data.errors);
                keys.forEach(key => {
                  response.data.errors[key].forEach(error => {
                    this.veeErrors.add({
                      field: key,
                      msg: error
                    });
                  });
                });
                if (response.data.errors.location_ids[0]) {
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: `Actualizar`,
                      text: `Error al tratar de almacenar este elemento, las siguientes localidades están asociadas a otro servicio ${response.data.errors.location_ids[0].map(d => d.location).join(", ")}`,
                      icon: "AlertOctagonIcon",
                      variant: "danger",
                    },
                  });
                  return;
                }
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Actualizar`,
                    text: `Error al tratar de almacenar este elemento`,
                    icon: "AlertOctagonIcon",
                    variant: "danger",
                  },
                });
              }
            });
        }
      });
    },
    async fetchProvinces() {
      await LocationService.provinces().then(({ data }) => {
        this.provinces = data.data;
      });
    },
    async fetchLocations() {
      await LocationService.locations_charge({
        province_id: this.service.province
      }).then(({ data }) => {
        this.locations = data.data;
      });
    },
    close() {
      this.$emit("close");
    }
  },
  async mounted() {
    await this.fetchProvinces();
    this.service.name = this.item.name;
    this.service.type = this.types.find(res => res.value == this.item.type);
    if (this.item.locations.length > 0) {
      this.service.province = this.item.locations[0].province.id;
      await this.fetchLocations();
      this.item.locations.forEach(element => {
        this.service.locations.push({
          value: element.id,
          text: element.location
        });
      });
    }
    if (this.item.percentage > 0) {
      this.charge_type = "porcentaje";
      this.service.percentage = this.item.percentage;
    } else if (this.item.amount > 0) {
      this.charge_type = "monto";
      this.service.amount = this.item.amount;
    }
    this.service.type = this.types.find(res => res.value == this.item.type);
  }
};
</script>

<style lang="scss" scoped>
.card-modal {
  height: 500px;
  display: block;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
.select-button-box {
  margin: 10px;
  text-align: center;
  button {
    margin-left: 1em;
  }
}
</style>
