<template>
  <div ref="container">
    <b-card class="pb-2">
      <div>
        <b-tabs>
          <b-tab title="ACTIVO" active>
            <b-card-title class="card-title mt-1 mx-2">
              <b-row class="row">
                <b-col cols="3">
                  <button class="btn btn-sm button-primary" @click="onAdd">
                    <feather-icon
                        size="15"
                        icon="PlusSquareIcon"
                        class="mr-50"
                      />
                    <span style="font-size: 15px">Agregar Servicio</span>
                  </button>
                </b-col>
                <b-col cols="9">
                  <filter-bar-component v-on:search="onSearch"></filter-bar-component>
                </b-col>
              </b-row>
            </b-card-title>
            <b-card-body class="card-body py-2 px-0">
              <full-table-component ref="fulltable" :fields="fields" :perPage="100" url="/services">
                <template slot="site" slot-scope="props">
                  <label
                    class="badge"
                    style="color: #7b7878; background-color: rgba(227, 228, 230, 0.8);"
                    v-if="props.rowData.locations[0] != null"
                  >{{ props.rowData.locations[0].province.province }}</label>
                  <label
                    class="badge"
                    style="color: #7b7878; background-color: rgba(227, 228, 230, 0.8);"
                    v-for="location in props.rowData.locations"
                    v-bind:key="location.id"
                  >{{location.location }}</label>
                </template>
                <template slot="buttons-slot" slot-scope="props">
                  <b-dropdown
                    class="p-0"
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template v-slot:button-content>
                      <feather-icon
                        size="20"
                        icon="ListIcon"
                      />
                    </template>
                    <b-dropdown-item @click="onView(props.rowData)">
                      <feather-icon
                        size="20"
                        icon="SearchIcon"
                      />
                      Ver detalles
                    </b-dropdown-item>
                    <b-dropdown-item @click="onEdit(props.rowData)">
                      <feather-icon
                        size="20"
                        icon="Edit3Icon"
                      />
                      Editar
                    </b-dropdown-item>
                    <b-dropdown-item @click="onDelete(props.rowData)">
                      <feather-icon
                        size="20"
                        icon="TrashIcon"
                      />
                      Eliminar
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
              </full-table-component>
            </b-card-body>
          </b-tab>
          <b-tab title="HISTORICO" @click="onOpenHistory()">
            <ServiceHistory v-if="openHistory"></ServiceHistory>
          </b-tab>
        </b-tabs>
      </div>
    </b-card>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { BCard, BTabs, BTab, BCardTitle, BRow, BCol, BCardBody, BDropdown, BDropdownItem } from 'bootstrap-vue'

import FilterBarComponent from "../../../layouts/components/FilterBarComponent";
import FullTableComponent from "../../../layouts/components/FullTableComponent";

import AlertService from "@/services/AlertService";
import UtilService from "@/services/UtilService";
import ServiceService from "@/services/ServiceService";

import ServiceHistory from "./ServiceHistory";
import ServiceAdd from "./ServiceAdd";
import ServiceView from "./ServiceView";
import ServiceEdit from "./ServiceEdit";

export default {
  name: "UserAll",
  components: {
    BCard, 
    BTabs, 
    BTab, 
    BCardTitle, 
    BRow, 
    BCol, 
    BCardBody, 
    BDropdown, 
    BDropdownItem,
    FilterBarComponent,
    FullTableComponent,
    ServiceHistory,
  },
  data() {
    return {
      fields: [
        {
          name: "id",
          title: "Id",
          sortField: "id"
        },
        {
          name: "created_at",
          title: "Creado",
          sortField: "created_at",
          formatter: value => {
            return UtilService.date(value);
          }
        },
        {
          name: "updated_at",
          title: "Actualizado",
          sortField: "updated_at",
          formatter: value => {
            return UtilService.date(value);
          }
        },
        {
          name: "name",
          title: "Nombre",
          sortField: "name"
        },
        {
          name: "percentage",
          title: "Porcentaje",
          sortField: "percentage"
        },
        {
          name: "amount",
          title: "Monto",
          sortField: "amount"
        },
        {
          name: "type",
          title: "Tipo",
          sortField: "type",
          formatter: value => {
            return this.typeSpanish(value);
          }
        },
        {
          name: "site",
          title: "Lugar",
          titleClass: "center aligned",
          dataClass: "center aligned"
        },
        {
          name: "buttons-slot",
          title: "Acciones",
          titleClass: "text-right",
          dataClass: "text-right align-top"
        }
      ],
      openHistory: false
    };
  },
  methods: {
    onSearch(event) {
      this.$refs.fulltable.search(event, {}, true);
    },
    onEdit(item) {
      this.$modal.show(
        ServiceEdit,
        {
          item: item,
          onSave: {
            handler: () => {
              this.$refs.fulltable.refresh();
            }
          }
        },
        {
          styles: "margin-left: 10%",
          adaptive: true,
          width: "50%",
          height: "auto"
        }
      );
    },
    typeSpanish(value) {
      if (value == "collect") {
        return "Colecta";
      } else {
        return "Otros";
      }
    },
    siteSeparated(rowData) {
      // `this` points to the vm instance
      let site = "";
      site += rowData.province;

      site += rowData.location;
      /*   site += data.name + " "; */
      /*   rowData.roles.map(data => {
        roles += data.name + " ";
      }); */
      return site;
    },
    statusBoolean(status) {
      if (status == "enabled") return true;
      else return false;
    },
    onAdd() {
      this.$modal.show(
        ServiceAdd,
        {
          onSave: {
            handler: () => {
              this.$refs.fulltable.refresh();
            }
          }
        },
        {
          styles: "margin-left: 10%",
          adaptive: true,
          width: "70%",
          height: "auto"
        }
      );
    },
    async onDelete(item) {
      const result = await AlertService.delete(
        "¿Estás seguro?",
        "Estas seguro de eliminar el usuario con el id " + item.id
      );
      if (result.value) {
        this.isLoading = true;
        ServiceService.destroy(item)
          .then(() => {
            this.isLoading = false;
            AlertService.info();
            this.$refs.fulltable.refresh();
          })
          .catch(error => {
            console.error(error);
            this.isLoading = false;
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Eliminar`,
                text: `Error al tratar de eliminar este elemento`,
                icon: "AlertOctagonIcon",
                variant: "danger",
              },
            });
          });
      }
    },
    async onView(item) {
      this.$modal.show(
        ServiceView,
        {
          service: item
        },
        {
          styles: "margin-left: 10%",
          adaptive: true,
          width: "70%",
          height: "auto"
        }
      );
    },
    onOpenHistory() {
      this.openHistory = true;
    }
  },
  computed: {},
  mounted() {
    if (this.$route.query.open) {
      this.onView({
        id: this.$route.query.open
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.card-body{
  padding: 0 !important;
}

</style>
