import axios from 'axios';

export default class ServiceService {

    /**
     * @description login
     * @param { username | login, password } values
     */
    static index() {
        return axios.get(process.env.VUE_APP_API + '/services', {
            params: {
                type: 'others'
            }
        });
    }

    /**
     * @description login
     * @param { username | login, password } values
     */
    static charges_transaction(id) {
        return axios.get(process.env.VUE_APP_API + '/charges/transaction/' + id);
    }
    static add_charges(data) {
        return axios.post(process.env.VUE_APP_API + '/charges', data);
    }

    /**
     * @description store data
     * @param data { location_id, street, height,  postal_code } values
     */
    static store(data) {
        return axios.post(process.env.VUE_APP_API + '/services', data);
    }

    /**
     * @description delete a element
     * @param { id } id point
     */
    static destroy({ id }) {
        return axios.delete(process.env.VUE_APP_API + '/services/' + id);
    }

    /**
     * @description update a element
     * @param { id } id point
     * @param values { location_id, street, height,  postal_code } update values
     */
    static update(id, values) {
        return axios.put(process.env.VUE_APP_API + '/services/' + id, values);
    }

    /**
     * @description get a element by id
     * @param { id } id point
     */
    static show(id) {
        return axios.get(process.env.VUE_APP_API + '/users/' + id);
    }


    static save(data, id) {
        return id ? this.update(id, data) : this.store(data);
    }


}
