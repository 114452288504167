<template>
  <div class="m-3">
    <div>
      <h3 class="title">DETALLE DE SERVICIO</h3>
      <div class="row justify-content-center">
        <div class="col-sm-12 col-md-12" v-if="view === 'show'">
          <div class="card mx-8 p-2">
            <div class="card-body p-3">
              <RowComponent :title="'ID:'" :content="service.id.toString()" />
              <RowComponent :title="'NOMBRE:'" :content="service.name" />
              <RowComponent :title="'TIPO:'" :content="service.type" />
                 <RowComponent :title="'PROVINCIA:'" v-if="service.locations[0]" :content="service.locations[0].province.province" />
           
               <div v-if="service.locations[0]">
                <p style="max-height: 200px; overflow: auto;">
                  <b>LOCALIDADES :</b>
                  <label
                    class="badge badge-primary mx-1"
                    v-bind:key="item.id"
                    v-for="item in  service.locations"
                  >{{ item.location }}</label>
                </p>
              </div>
              <RowComponent :title="'PORCENTAJE:'" :content="service.percentage + '%'" />
              <RowComponent :title="'MONTO:'" :content="service.amount" />
              <div>
                <p>
                  <b>CREADO :</b>
                  <span>{{service.created_at | date_format}}</span>
                </p>
              </div>
              <div>
                <p>
                  <b>ACTUALIZADO :</b>
                  <span>{{service.updated_at | date_format}}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-12" v-else>
          
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: "UserView",
  props: {
    service: Object
  },
  components: {
   
  },
  data() {
    return {
      view: "show",
    };
  },
  methods: {
    onClose() {
      this.$emit("close");
    },
    onChange() {
      this.view = "change";
    },
    onCloseChange($event) {
      this.view = "show";
      if ($event.change) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Actualizar`,
            text: `Contraseña actualizada correctamente`,
            icon: "InfoIcon",
            variant: "info",
          },
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.card-modal {
  height: 500px;
  display: block;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
