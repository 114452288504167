<template>
  <div ref="container">
    <b-card-title class="card-title m-0">
      <b-row class="row">
        <b-col cols="12">
          <filter-bar-component v-on:search="onSearch"></filter-bar-component>
        </b-col>
      </b-row>
    </b-card-title>
    <b-card-body class="card-body py-2 px-0">
      <full-table-component ref="fulltable" :fields="fields" url="/services/history">
        <template slot="site" slot-scope="props">
          <label
            class="badge badge-secondary"
            v-if="props.rowData.locations[0] != null"
          >{{ props.rowData.locations[0].province.province }}</label>
          <label
            class="badge badge-primary"
            v-for="location in props.rowData.locations"
            v-bind:key="location.id"
          >{{location.location }}</label>
        </template>
        <template slot="buttons-slot" slot-scope="props">
          <b-dropdown class="p-0" variant="link" toggle-class="text-decoration-none" no-caret>
            <template v-slot:button-content>
              <feather-icon
                size="20"
                icon="ListIcon"
              />
            </template>
            <b-dropdown-item @click="onView(props.rowData)">
              <feather-icon
                size="20"
                icon="SearchIcon"
              />
              Ver detalles
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </full-table-component>
    </b-card-body>
  </div>
</template>

<script>
import { BCardTitle, BRow, BCol, BCardBody, BDropdown, BDropdownItem  } from 'bootstrap-vue'

import FilterBarComponent from "@/layouts/components/FilterBarComponent";
import FullTableComponent from "@/layouts/components/FullTableComponent";

import UtilService from "@/services/UtilService";

import ServiceView from "./ServiceView";
import ServiceEdit from "./ServiceEdit";

export default {
  name: "UserAll",
  components: {
    BCardTitle, 
    BRow, 
    BCol, 
    BCardBody, 
    BDropdown, 
    BDropdownItem,
    FilterBarComponent,
    FullTableComponent
  },
  data() {
    return {
      fields: [
        {
          name: "id",
          title: "ID",
          sortField: "id"
        },
        {
          name: "created_at",
          title: "CREADO",
          sortField: "created_at",
          formatter: value => {
            return UtilService.date(value);
          }
        },
        {
          name: "updated_at",
          title: "ACTUALIZADO",
          sortField: "updated_at",
          formatter: value => {
            return UtilService.date(value);
          }
        },
        {
          name: "name",
          title: "NOMBRE",
          sortField: "name"
        },
        {
          name: "percentage",
          title: "PORCENTAJE",
          sortField: "percentage"
        },
        {
          name: "amount",
          title: "MONTO",
          sortField: "amount"
        },
        {
          name: "type",
          title: "Tipo",
          sortField: "type",
          formatter: value => {
            return this.typeSpanish(value);
          }
        },
        {
          name: "site",
          title: "Lugar",
          titleClass: "center aligned",
          dataClass: "center aligned"
        },
        {
          name: "buttons-slot",
          title: "",
          titleClass: "text-right",
          dataClass: "text-right align-middle"
        }
      ]
    };
  },
  methods: {
    onSearch(event) {
      this.$refs.fulltable.search(event, {}, true);
    },
    onEdit(item) {
      this.$modal.show(
        ServiceEdit,
        {
          item: item,
          onSave: {
            handler: () => {
              this.$refs.fulltable.refresh();
            }
          }
        },
        {
          styles: "margin-left: 10%",
          adaptive: true,
          width: "50%",
          height: "50%"
        }
      );
    },
    typeSpanish(value) {
      if (value == "collect") {
        return "Colecta";
      } else {
        return "Otros";
      }
    },
    siteSeparated(rowData) {
      let site = "";
      site += rowData.province;
      site += rowData.location;
      return site;
    },
    statusBoolean(status) {
      if (status == "enabled") return true;
      else return false;
    },
    async onView(item) {
      this.$modal.show(
        ServiceView,
        {
          service: item
        },
        {
          styles: "margin-left: 10%",
          adaptive: true,
          width: "70%",
          height: "auto"
        }
      );
    }
  },
  computed: {},
  mounted() {
    if (this.$route.query.open) {
      this.onView({
        id: this.$route.query.open
      });
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
