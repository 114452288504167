var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"m-3"},[_c('div',{staticClass:"card-body card-modal pt-0"},[_c('div',{staticStyle:{"height":"500px"}},[_c('h3',{staticClass:"title"},[_vm._v("Agregar Servicio")]),_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('validation-observer',{ref:"addServiceForm"},[_c('b-form',{on:{"submit":_vm.onSubmit}},[_c('b-form-group',{attrs:{"label":"Nombre"}},[_c('validation-provider',{attrs:{"name":"Nombre","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"size":"sm","name":"name","type":"text","state":errors.length > 0 ? false : null},model:{value:(_vm.service.name),callback:function ($$v) {_vm.$set(_vm.service, "name", $$v)},expression:"service.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Tipo de servicio"}},[_c('validation-provider',{attrs:{"name":"Tipo de Servicio","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('multiselect',{attrs:{"name":"type","options":_vm.types,"multiple":false,"label":"label","track-by":"label","state":errors.length > 0 ? false : null},model:{value:(_vm.service.type),callback:function ($$v) {_vm.$set(_vm.service, "type", $$v)},expression:"service.type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.service.type && _vm.service.type.label == 'colecta')?_c('b-form-group',{attrs:{"label":"Provincia"}},[_c('validation-provider',{attrs:{"name":"Provincia","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"name":"province","size":"sm","state":errors.length > 0 ? false : null,"options":_vm.provinces},on:{"input":_vm.fetchLocations},model:{value:(_vm.service.province),callback:function ($$v) {_vm.$set(_vm.service, "province", $$v)},expression:"service.province"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1451769303)})],1):_vm._e(),(_vm.service.type && _vm.service.type.label == 'colecta')?_c('b-form-group',{attrs:{"label":"Localidad"}},[_c('validation-provider',{attrs:{"name":"Localidad","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('multiselect',{attrs:{"name":"location","options":_vm.locations,"multiple":true,"label":"text","track-by":"text","state":errors.length > 0 ? false : null},model:{value:(_vm.service.locations),callback:function ($$v) {_vm.$set(_vm.service, "locations", $$v)},expression:"service.locations"}},[_c('template',{slot:"beforeList"},[_c('div',{staticClass:"select-button-box"},[_c('b-button',{staticClass:"mr-1",attrs:{"size":"sm","variant":"success"},on:{"click":function($event){return _vm.selectAllLocations(_vm.locations)}}},[_vm._v("Seleccionar todos")]),_c('b-button',{staticClass:"mr-1",attrs:{"size":"sm","variant":"danger"},on:{"click":function($event){return _vm.clearAllLocations()}}},[_vm._v("Limpiar")])],1)])],2),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2290798077)})],1):_vm._e(),_c('b-form-group',{attrs:{"label":"Tipo de cargo"}},[_c('validation-provider',{attrs:{"name":"Tipo de cargo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('multiselect',{attrs:{"name":"charge_type","options":_vm.charge_types,"multiple":false,"state":errors.length > 0 ? false : null},model:{value:(_vm.charge_type),callback:function ($$v) {_vm.charge_type=$$v},expression:"charge_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.charge_type == 'porcentaje')?_c('b-form-group',{attrs:{"label":"Porcentaje de cargo"}},[_c('validation-provider',{attrs:{"name":"Porcentaje","rules":"required|integer|min:1|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"size":"sm","name":"percentage","type":"number","placeholder":"%","state":errors.length > 0 ? false : null},model:{value:(_vm.service.percentage),callback:function ($$v) {_vm.$set(_vm.service, "percentage", $$v)},expression:"service.percentage"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,318247892)})],1):_vm._e(),(_vm.charge_type == 'monto')?_c('b-form-group',{attrs:{"label":"Monto"}},[_c('validation-provider',{attrs:{"name":"Monto","rules":"required|min:1|max:50000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"size":"sm","name":"amount","type":"number","placeholder":"100","state":errors.length > 0 ? false : null},model:{value:(_vm.service.amount),callback:function ($$v) {_vm.$set(_vm.service, "amount", $$v)},expression:"service.amount"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,208897088)})],1):_vm._e()],1)],1)],1)])])]),_c('footer',{},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('b-button',{staticClass:"mr-1 float-right",attrs:{"size":"sm","variant":"primary"},on:{"click":_vm.onSubmit}},[_vm._v("Guardar")]),_c('b-button',{staticClass:"mr-1 float-right",attrs:{"size":"sm","variant":"outline-primary"},on:{"click":_vm.close}},[_vm._v("Cancelar")])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }